.Model-main {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Model-borders {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(255,255,255,0.2);
    border-top: 0;
    min-width: 55%;
    padding: 5rem;
    flex-direction: column;
}