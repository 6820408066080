.Options-main {
    max-width: 50rem;
    margin-top: 2rem;
}
.Options-api {
    display: flex;
    align-items: center;
    position: relative;
}
/* 
.Options-api i {
    margin-bottom: 1.5rem;
    border: 0.1rem solid var(--main-color);
    background: var(--main-color);
    padding: 1.2rem;
    color: white;
    font-weight: 400;
    margin-left: -2rem;
    cursor: pointer;
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;    
} */

.Options-api i {
    margin-bottom: 1.5rem;
    border: 0.1rem solid white;
    /* background: var(--main-color); */
    padding: 1.1rem;
    color: white;
    font-weight: 400;
    /* margin-left: -2rem; */
    cursor: pointer;
    /* border-top-right-radius: 0.4rem; */
    /* border-bottom-right-radius: 0.4rem; */
}
.Options-api i:hover {
    color: white;
    font-weight: 600;
 
}

.Options-label {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: .5rem;
}

.Options-code {
    background: #f5f5fa;
    padding: 3rem;
    font-family: monospace;
}
.Options-code-line {
    display: block;
    margin-left: 2rem;
}

.Options-copy-notice {
    position: absolute;
    right: -16rem;
    top: 0.5rem;
    animation: fadeOut 1s forwards;
    animation-delay: 1s;
    /* text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #FF008C, 0 0 30px #FF008C, 0 0 40px #FF008C, 0 0 55px #FF008C, 0 0 75px #FF008C; */
    text-shadow: 0 0 3px #fff, 0 0 8px #fff, 0 0 15px #FF008C, 0 0 20px #FF008C, 0 0 25px;
}


@keyframes fadeOut{
    from {opacity: 1;}
    to {opacity: 0;}
}

#apiUrl {
    color: var(--main-color)
}