.Form-main {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5e5e5;
    border-top: 0;
    min-width: 75%;
    padding: 5rem;
}

form {
    min-width: 50rem;
    position: relative;
}

.Form-info{
    min-width: 50rem;
    position: relative;
}
.Form-buttons {
    display: flex;
    justify-content: space-between;
    margin-right: -2rem;
    margin-top: 2rem;
}

.Form-loader{
    display: flex;
    align-items: center;
    margin-top: 2rem;
    justify-content: center;
}

.Form-loader span{
    margin-left: 1rem;
}

.Form-edit{
    position: absolute;
    right: 0;
    top: 1rem;
    display: flex;
    align-items: center;
}

.Form-edit:hover{
    color: var(--secondary-color);
    cursor: pointer;
}

.Form-edit span{
    padding-right: 0.5rem;
}