.Main-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Main-main h1 {
    width: 50rem;
}

.Main-yourModels{
    display: flex;
    width: 50rem;
    align-items: center;
    margin-top: 4rem;
}