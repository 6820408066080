:root {
  --main-color: #3bc8ff;
  --secondary-color: #0e14d4;
  --background: #010114;
}

html {
  font-size: 62.5%;
  height: 100%;
}

#root {
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
  font-family: Saira, sans-serif;
  background: var(--background); /*#606c76;*/
  color: white;
  font-size: 1.6em;
  font-weight: 300;
  letter-spacing: .01em;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type=text] {
  -webkit-appearance: none;
  background: transparent;
  padding: 10px 15px;
  padding-left: 0;
  color: #fff;
  font-size: 100%;
  border: none;
  border-bottom: 1px solid rgba(255,255,255,1);
  margin-bottom: 15px;
  width: 100%;
  max-width: 100%;
}
/* input[type=text], textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0.1rem solid #d1d1d1;
  color: white;
  border-radius: .4rem;
  box-shadow: none;
  box-sizing: inherit;
  height: 2.8rem;
  padding: .6rem 1.0rem;
  width: 100%;
} */

::placeholder{
  color: rgba(200,200,255,.4);
}
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0.1rem solid #d1d1d1;
  color: white;
  border-radius: .4rem;
  box-shadow: none;
  box-sizing: inherit;
  height: 2.8rem;
  padding: 1.0rem 1.4rem;
  width: 100%;
  /* width: 400px; */
}
textarea {
  min-height: 6.5rem;
}

textarea:focus, input:focus{
  outline: none;
}

label {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: .5rem;
}
fieldset, input, select, textarea {
  margin-bottom: 1.5rem;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button, button, input[type='button'],  input[type='submit'] {
  background-color: var(--secondary-color);
  border: 0.1rem solid var(--secondary-color);
  border-radius: .4rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 700;
  height: 3.8rem;
  letter-spacing: .1rem;
  line-height: 3.8rem;
  padding: 0 3.0rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}

.button-outline, input[type='reset']{
  background-color: transparent;
  border-color: white;
  color: white;

}

h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
    letter-spacing: -.1rem;
    margin-bottom: 2.0rem;
    margin-top: 0;
}

h1 {
  margin: 0.67em 0;
}

h2 {
  font-size: 3.6rem;
  line-height: 1.25;
}