.ModelRow-main {
    border-bottom: 1px solid #e5e5e5;
    width: 50rem;
    display: flex;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    cursor: pointer;

}
.ModelRow-main:hover{
    /* background: #f9f9f9; */
}

.ModelRow-name {
    font-size: 2rem;
    color: var(--main-color);
}

.ModelRow-icon {
    font-size: 3rem;
    /* margin-right: 2rem; */
}

.ModelRow-icon:hover {
    /* color: red; */
}

.ModelRow-avatar{
    min-width: 6rem;
    min-height: 6rem;
    background: var(--main-color);
    border-radius: 6rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    text-transform: uppercase;    
}