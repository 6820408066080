.Upload-main {
    border: 0.2rem dashed #d1d1d1;
    border-radius: .4rem;
    height: 10rem;
    margin-right: -2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
}
.Upload-drop {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.Upload-drop:focus {
    outline: none;
}
.Upload-loader{
    display: flex;
    align-items: center;
    justify-content: center;
}

.Upload-loader span {
    margin-left: 1rem;
}

.Upload-error {
    color: #f44336;
}

.Upload-radiobuttons{
    display: flex;
    margin-bottom: 2rem;
}

.Upload-training{
    max-width: 50rem;
}

.Upload-option{
    display: flex;
}

.Upload-option span{
    margin-left: 0.5rem;
    margin-right: 1.5rem;
}
.Upload-button {
    width: 2rem;
    height: 2rem;
    border: 2px solid var(--main-color);
    border-radius: 2rem;
    position: relative;
    cursor: pointer;
}

.Upload-button-selected::before{
    content: "";
    display: block;
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--main-color);
    border-radius: 100%;
  }