.TopBar-icon {
    height: 3rem;
    padding: 1rem;
}

.TopBar-main {
    background: var(--background);;
    height: 6rem;
    /* margin-bottom: 4rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;    
}

.TopBar-name {
    color: white;
    font-size: 2.5rem;
    font-family: Bangers;
    letter-spacing: 0.3rem;
    margin-left: 0.5rem;
}

.TopBar-logo {
    align-items: center;
    display: flex;
    flex: 0 0 auto;    
}

.TopBar-user {
    color: white;
    margin-right: 1rem;
    font-weight: 600;
}

.TopBar-exit {
    margin-right: 1rem;
    font-size: 2rem;
    font-weight: 600;
    color: white;
}

.TopBar-right {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    justify-content: flex-end;

}

.TopBar-routes {
    display: flex;
    align-items: center;
    color: white;
    font-weight: 600;
    flex: 0 0 auto;
    margin-left: 2rem;
    text-transform: uppercase;
}

.TopBar-routes span {
    margin-left: 2rem;
}

.TopBar-routes span:hover {
    color: var(--secondary-color);
    cursor: pointer;
}

.TopBar-error {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%; 
    height: 100%;
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8); 
    z-index: 2;
    cursor: pointer;
}