.ModelList-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}

.ModelList-more{
    margin-top: 2rem;
    display: flex;
}

.ModelList-paginate {
    margin-right: 3rem;
    cursor: pointer;
    display: flex;
    align-items: center;    
}

.ModelList-paginate:hover {
    color: var(--secondary-color)
}

.ModelList-paginate-inactive:hover {
    color: gray; 
}
.ModelList-paginate-inactive {
    color: gray;
}

.ModelList-paginate span {
    margin-left: 1rem;
    margin-right: 1rem;
}