.ModelStatus-main {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

/* .ModelStatus-main span {
    margin-right: 1rem;
} */
.ModelStatus-statustext {
    margin-left: 1rem;
}
.ModelStatus-status {
    display: flex;
    align-items: center;
}

.ModelStatus-ready {
    color: var(--main-color);
    font-weight: 600;
    font-size: 2rem;
    margin-left: 1rem;
}
.ModelStatus-label {
    font-size: 1.6rem;
    font-weight: 700;
    margin-right: 2rem;
}
