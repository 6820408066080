.RemoveModel-main {
    max-width: 50rem;
    margin-top: 2rem;
    width: 100%;
}

.RemoveModel-title{
    display: flex;
    align-items: center;
}

.RemoveModel-title i{
    margin-left: 1rem;
    cursor: pointer;
}

.RemoveModel-title i:hover{
    color: var(--secondary-color);
}

.RemoveModel-main h2{
    margin-bottom: 0;
}