.Activation-main {
    text-align: center;
}

.Activation-inputs{
    margin-bottom: 2rem;
}

.Activation-main input {
    background: black;
    width: 5rem;
    height: 5rem;
    border: 1px solid white;
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 2rem;
    text-transform: uppercase;
}

.Activation-processing{
    display: flex;
    justify-content: center;
}

.Activation-processing span{
    margin-left: 2rem;
}

.Activation-error {
    margin-bottom: 3rem;
    color: #F44336;
}